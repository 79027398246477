import { ShiftId } from "./shifts"

type TaskId =
    "koa-out" |
    "koa-in" |
    "feed-birds" |
    "put-away-dishes" |
    "let-roman-out" |
    "put-roman-in" |
    "gandalf-morning" |
    "gandalf-after-breakfast" |
    "gandalf-afternoon" |
    "dog-food" |
    "cat-food-morning" |
    "cat-food-evening" |
    "rabbit-morning" |
    "rabbit-afternoon" |
    "guinea-pigs-morning" |
    "guinea-pigs-afternoon" |
    "mulch-morning" |
    "mulch-afternoon" |
    "sweep" |
    "water-plants"

export interface Task {
    id: TaskId;
    title: string;
    description: string;
    shiftId: ShiftId;
    imgUrl?: string;
}

export const tasks: Task[] = [
    {
        id: "koa-out",
        title: "Koa Out",
        description: "Let Koa out of Cathy's room. He goes out the front porch door. If you pick him up he could end you.",
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1070.jpg"
    },
    {
        id: "feed-birds",
        title: "Feed Birds",
        description: "Give the birds a pile (about 6 handfuls worth) of seed. Spread it out, but not too close to the edge.",
        shiftId: "morning",
        imgUrl: "https://media.istockphoto.com/photos/bird-seed-for-parrots-picture-id584599164"
    },
    {
        id: "put-away-dishes",
        title: "Dishes Away",
        description: "Put away the dry dishes from the night before.",
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1075.jpg"
    },
    {
        id: "let-roman-out",
        title: "Roman Out",
        description: `Let Roman out of his crate and put the striped blanket up for air to 
        get in during the day. Put his gray blanket up on his house.  

        Let Milo come inside and let Roman out to pee/poop. Walk with him to the bird feeder area through the grass and he should get his morning business done. .`,
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1082.jpg"
    },
    {
        id: "put-roman-in",
        title: "Roman In",
        description: `The dogs go out of the house around 7-8 pm. Roman gets tired and the word is, are you ready for 
        SLEEPY? When he goes out, be careful that Milo doesn't bulldoze him down the stairs. 
        Tell Roman to "GO PEE". He doesn't like to do it in the rain so try to take him out when it's not. He has 
        to go before he goes to bed because he has to hold it for 12 hours. He doesn't drink water in his house 
        even when we used to put some in there. If he gets rained on, please dry him off. 
        He usually stands at his door opening waiting to see if his blankets need to be straightened out. If it's ok, 
        point inside and tell him go lay down, it's sleepy time. Cover him up with the gray blanket and put the 
        lock down on his door. Put the striped blanket down. And say Goodnight Roman Milo will just go lay 
        on his bed by the front of the old green car. I tell him Goodnight Milo and go pet him too.`,
        shiftId: "night",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1073.jpg"
    },
    {
        id: "dog-food",
        title: "Dog Food",
        description: "Make sure the dogs bowls are clean and have water. Milo drinks a LOT",
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1087.jpg"
    },
    {
        id: "cat-food-morning",
        title: "Cat Food",
        description: `Toss old food if any from overnight out into the grass. Birds will find it. (It's not good for the 
        dogs pancreas to eat cat food.) 
        Give one full cup of dry food for Leo in the double bowl under the carpeted tower. 
        Koa eats up on the top of the pedestal when he's outside. Give him just a small handful outside 
        in the morning. 
        * Please give a spoon or two of wet food for a little treat and sprinkle half a Hemp tablet over 
        Koa's wet food to help chill him out.. Wash food bowls regularly in the outside sink, not the 
        kitchen please. 
        Check that the double bowl has clean water around the "moat" to keep fire ants out of the 
        food. (Please change/add water every other day or so).`,
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/65535580380--72E9019D-E6AA-47EC-986D-36456F196E1B.jpg"
    },
    {
        id: "gandalf-morning",
        title: "Gandalf",
        description: "Wet food. Water. Litter",
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-0596.jpg"
    },
    {
        id: "gandalf-after-breakfast",
        title: "Gandalf",
        description: "Wet food. Litter",
        shiftId: "after-breakfast",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-0884.jpg"
    },
    {
        id: "gandalf-afternoon",
        title: "Gandalf",
        description: "Wet food. Litter",
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-0545-edit.jpg"
    },
    {
        id: "rabbit-morning",
        title: "Avery (Bun-Bun)",
        description: `In the morning give him a small handful worth of food and fresh water. 
        Give him a clump of the hay onto his board next to his water, toss the older stuff under the cage after 2 days.`,
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1100.jpg"
    },
    {
        id: "rabbit-afternoon",
        title: "Avery (Bun-Bun)",
        description: `In the afternoon just see if he needs a little more pellets and one timothy circle treat and check his 
        water bowl. His hay will probably still be there. Add a handful if not`,
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1080.jpg"
    },
    {
        id: "guinea-pigs-morning",
        title: "Fiona, Ginger and Pippy",
        description: `
        The girls get some treats handed through the cage wire. It can be 2 slices of cucumber each 
        OR a slice of bell pepper each (if I don't have those sometimes they can have a grape each or a small 
        slice of apple without seeds). Also a handful or 2 of guinea pig food in the roundish container. They also 
        get 3 handfuls of hay and 3 handfuls of Kale. This takes them through to dinner time. **** usually push the poop off the board with the bottom of the bowl and toss out the leftover food in 
        the morning `,
        shiftId: "morning",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1084.jpg"
    },
    {
        id: "guinea-pigs-afternoon",
        title: "Fiona, Ginger and Pippy",
        description: `
        Just add a little of the pellets and another good handful of hay, Refill water bottle if its below halfway.`,
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1085.jpg"
    },
    {
        id: "mulch-morning",
        title: "Mulch",
        description: `Mulch container needs to be dumped out into the compost bin in back then washed 
        out in outside sink. See if blue recycle bin needs emptying. Check under sink trash if full or has 
        gnats, dump the trash into outside can and reuse bag if not wet and yucky.`,
        shiftId: "after-breakfast",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1071.jpg"

    },
    {
        id: "mulch-afternoon",
        title: "Mulch",
        description: `Mulch container needs to be dumped out into the compost bin in back then washed 
        out in outside sink`,
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1071.jpg"

    },
    {
        id: "sweep",
        title: "Sweep Kitchen",
        description: "Vacuum the living room and kitchen daily. Every other day please sweep down the stairs.",
        shiftId: "after-breakfast",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1072.jpg"
    },
    {
        id: "cat-food-evening",
        title: "Cat Food",
        description: `Feed the cats dry food. The cats really enjoy the crunchy cat 
        treats, give about 6-8 pieces in the evening when Leo puts his feet up on the back door window. 
        Koa can get his in Cathy's room.`,
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1079.jpg"
    },
    {
        id: "koa-in",
        title: "Koa In",
        description: `When he is brought inside, give him his dry food from Cathy's room. 
        ***Before dark around 4-5pm try to entice Koa to come inside to Cathy's room for the night. if 
        he doesn't want you to pick him up, try to tap on the dish and see if you can get him to walk 
        inside on his own.(Then of course let him out in the morning around 6-7ish.) He has a litterbox 
        but prefers to hold it and pee outside. `,
        shiftId: "late-afternoon",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1066.jpg"
    },
    {
        id: "water-plants",
        title: "Water Plants",
        description: `Water the plants every 4 days (12th, 16th, 20th, 24th). They are in front of the house, on the path to the shower, and in a circle around the water catchment.`,
        shiftId: "after-breakfast",
        imgUrl: "https://moho-pet-sit.s3.amazonaws.com/IMG-1096.jpg"
    },

]
