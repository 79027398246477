import { ShiftCardProps } from './../components/Shift'

export type ShiftId =
    // "early-morning" |
    "morning" |
    "after-breakfast" |
    // "evening" |
    "late-afternoon" |
    "night"

export interface Shift {
    id: ShiftId
    title: string;
    time: string;
}

export const shifts: Shift[] = [
    // {
    //     id: "early-morning",
    //     title: "Early Morning",
    //     time: "5:30"
    // },
    {
        id: "morning",
        title: "Morning",
        time: "6:30"
    },
    {
        id: "after-breakfast",
        title: "After Breakfast",
        time: "10:00"
    },
    // {
    //     id: "evening",
    //     title: "Evening",
    //     time: "16:00"
    // },
    {
        id: "late-afternoon",
        title: "Afternoon",
        time: "17:00"
    },
    {
        id: "night",
        title: "Night",
        time: "20:00"
    }
]
