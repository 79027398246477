import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { useDarkTheme } from '../contexts/MuiTheme';
import PetsIcon from '@mui/icons-material/Pets';
import shifts from './Shift'
import { Box } from '@material-ui/core';
import paw from './../assets/paw.svg'
// import ModalDialog from './ModalDialog';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "sticky",
    top: 0,
    padding: "0px 16px",
    '@media (min-width: 1108px)': {
      padding: "0px 40px"
    }
    // backgroundColor: "white"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "flex",
    gap: 6
  },
  icon: {
    // paddingLeft: 8
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { darkTheme, setDarkTheme } = useDarkTheme();

  const handleOpen = () => {
    setOpen(true);
    setDarkTheme(_ => !_)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AppBar
      position="static"
      className={classes.appBar}>
      <Toolbar>
        <Box className={classes.title}>
          {/* <PetsIcon className={classes.icon} /> */}
          <img src={paw} alt="React Logo" style={{height: 32}}/>
          <Typography variant="h5" >
            PetSit
          </Typography>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {/* <Button color="inherit" onClick={handleOpen}>
          Login
        </Button> */}
      </Toolbar>
      {/* <ModalDialog open={open} handleClose={handleClose} /> */}
    </AppBar>
  );
};

export default Navbar;