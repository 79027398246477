import React from 'react';
import Typography from '@mui/material/Typography';
import "@fontsource/open-sans";
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Shift } from '../data/shifts';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularStatic from './CircularProgress'
import moment from 'moment';





// import ModalDialog from './ModalDialog';
const useStyles = makeStyles(theme => ({
    root: {
        // fontFamily: "Open Sans",
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between",
        // backgroundColor: `${theme.palette.background.paper} !important`
        flex: "0 0 345px",
        // '@media (min-width: 1100px)': {
        //     flex: "0 0 280px"
        // },
        paddingBottom: 32
    },
    top: {
        display: "flex",
        // flexDirection: "column",
        justifyContent: "space-between",
    }
}));

export interface ShiftCardProps {
    shift: Shift
}

const ShiftCard: React.FC<ShiftCardProps> = ({ shift }) => {
    const classes = useStyles();
    const { title, time } = shift;
    return (<>
        {/* <Card
            sx={{ maxWidth: 345, minWidth: 345 }}
            className={classes.root}
        > */}
        {/* <CardMedia
          component="img"
          alt="green iguana"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
        /> */}
        {/* <CardContent> */}
        <Box
            className={classes.root}
        >
            <Box
                className={classes.top}
            >

                <Typography gutterBottom variant="h5" component="div">
                    {moment(time, "hh:mm").format('h:mm')}
                    <span style={{ fontSize: "1rem" }}>
                        {` ${moment(time, "hh:mm").format('A')}`}
                    </span>
                </Typography>
                {/* <CircularStatic /> */}
            </Box>
            {/* <Divider light /> */}

            <Typography variant="body2" color="text.secondary">
                {title}
            </Typography>
            {/* <Typography className={classes.clock} variant="h2">
                    {time}
                </Typography> */}
        </Box>
        {/* </CardContent> */}
        {/* </Card> */}

    </>
    );
};

export default ShiftCard;