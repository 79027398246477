import React, { createContext, useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PaletteOptions } from '@mui/material';
import "@fontsource/open-sans";
import "@fontsource/montserrat";
import { borderRadius } from '@mui/system';



// Dark Theme Context
interface DarkThemeContextType {
    darkTheme: boolean;
    setDarkTheme: React.Dispatch<React.SetStateAction<boolean>>
}

const DarkThemeContext = createContext<DarkThemeContextType>(undefined!);

export const useDarkTheme = (): DarkThemeContextType => {
    const context = React.useContext(DarkThemeContext);
    if (!context) {
        throw new Error("This component must be used within a <MuiThemeProvider> component.")
    }
    return context;
}

// Material UI Theme Context plus Dark Context
export const MuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const [darkTheme, setDarkTheme] = useState<boolean>(false);

    // Theme setup
    const light = {
        primary: { main: '#262524' },
        secondary: { main: '#00A9E1' },
        background: {
            default: "#f7f7f7",
            paper: "#ffffff"
        },

    };

    // https://material-ui.com/customization/palette/
    const dark = {
        primary: { main: '#262524' },
        secondary: { main: '#00A9E1' },
        background: {
            default: "#202124",
            paper: "#303134"
        },
        type: 'dark',
    };
    // const cool: PaletteOptions = 1;

    // https://mui.com/customization/typography/
    const theme = createTheme({
        palette: darkTheme ? dark : light,
        typography: {
            // fontFamily: `"Open Sans", sans-serif`,
            fontFamily: `"Montserrat", sans-serif !important`,

        },
        overrides: {
            MuiTypography: {
                h5: {
                    fontWeight: 600
                },
                h4: {
                    fontWeight: 600
                }
            },
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: "white",
                    color: "black"
                },
                root: {
                    boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 12px !important;'
                }
            },
            // MuiButton: {
            //     containedPrimary: {
            //         color: '#e38892 !important',
            //         backgroundColor: '#fff6f7 !important',
            //         borderRadius: '40px !important',
            //         boxShadow: "none !important"
            //     }
            // },
            MuiButtonBase: {
                root: {
                    fontFamily: `"Montserrat", sans-serif !important`,
                }
            },
            MuiPaper: {
                rounded: {
                    border: '1px solid rgb(221, 221, 221) !important',
                    borderRadius: '12px !important',
                    boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 16px !important'
                }
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <DarkThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
                {children}
            </DarkThemeContext.Provider >
        </ThemeProvider>
    )
};

