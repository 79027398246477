import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar'
import AnimalSchedule from './components/AnimalSchedule'

function App() {
  return (<>
    <Navbar />
    <AnimalSchedule/>
  </>
  );
}

export default App;
