import { Box, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ShiftCard from './Shift';
import TaskCard from './Task'
import { shifts } from './../data/shifts'
import { tasks } from './../data/tasks'
import Calendar from './Calendar'

// import ModalDialog from './ModalDialog';
const useStyles = makeStyles(theme => ({
    schedule: {
        padding: 32,
        backgroundColor: `${theme.palette.background.default} !important`
    },
    shift: {
        padding: '32px 0px',

        display: "flex",
        flexFlow: "wrap",
        // flexDirection: "column",
        // alignItems: "center",
        // margin: "auto",
        // display: "grid",
        // alignContent: "center",
        // justifyItems: "center",
        justifyContent: "center",
        // alignItems: "center",
        // gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
        // gridTemplateColumns: "minmax(200px, 1fr) minmax(600px, 1fr)",
        // gap: "1rem"
        backgroundColor: `${theme.palette.background.default} !important`

    },
    taskContainer: {
        // paddingTop: 32,
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // width: 100%;
        margin: "auto",
        width: 'auto',
        display: "grid",
        alignItems: "stretch",
        // alignItems: "stretch",
        gridTemplateColumns: "repeat(auto-fill, minmax(345px, 1fr))",
        gap: "1rem",
        maxWidth: 600,
        '@media (min-width: 1250px)': {
            maxWidth: 760
        }
    },
    calendarContainer: {
        padding: "32px 0px 16px",
        display: "flex",
        justifyContent: "space-between",
        // backgroundColor: `${theme.palette.background.paper} !important`
    },

}));

interface AnimalScheduleProps {
}


const AnimalSchedule: React.FC<AnimalScheduleProps> = () => {
    const classes = useStyles();

    return (<>

        <Container className={classes.schedule} maxWidth="lg">
            {/* <Container className={classes.shift} maxWidth="lg">
                <Shift title="Morning" time="6:30"  />
                <Container className={classes.taskContainer} maxWidth="md">
                    <Task taskId="put-away-dishes" />
                    <Task taskId="feed-birds" />
                    <Task taskId="let-roman-out" />
                </Container>
            </Container>
            <Divider light />
            <Container className={classes.shift} maxWidth="lg">
                <Shift title="After Breakfast" time="10:00"/>
                <Container className={classes.taskContainer} maxWidth="md">
                    <Task taskId="sweep" />
                </Container>
            </Container> */}
            <Box className={classes.calendarContainer}>
                <Typography gutterBottom variant="h5" component="div">
                    Todo
                </Typography>
                <Calendar />
            </Box>
            {shifts.map((s, i) => {
                return (<>
                    <Divider light />
                    <Container key={i} className={classes.shift} maxWidth="lg">
                        <ShiftCard shift={s} />
                        <Container className={classes.taskContainer} maxWidth="md">
                            {
                                tasks
                                    .filter(t => t.shiftId === s.id)
                                    .map((t, j) => {
                                        return (
                                            <TaskCard key={j} task={t} />
                                        )
                                    })
                            }
                            {/* <Task taskId="put-away-dishes" />
                            <Task taskId="feed-birds" />
                            <Task taskId="let-roman-out" /> */}
                        </Container>
                    </Container>
                </>
                )
            })}
        </Container>
    </>
    );
};
export default AnimalSchedule;