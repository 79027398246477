import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';
import { ShiftId } from './../data/shifts';
import { Task } from './../data/tasks';


interface TaskCardProps {
  task: Task;
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: `${theme.palette.background.paper} !important`
  },
}));


const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
  const classes = useStyles();
  const { title, description, imgUrl } = task;
  return (
    <Card
      sx={{ maxWidth: 345, minWidth: 345 }}
      className={classes.root}
    >
      {
        !!imgUrl ? <>
          <CardMedia
            component="img"
            alt={title}
            height="140"
            image={imgUrl}
          />
        </>
          : <></>

      }

      <CardContent style={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h5" component="div"
          style={{ fontWeight: 600, fontSize: 21 }}>
          {title}
        </Typography>
        <Typography  variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" size="small" sx={{
          fontWeight: 600,
          color: '#e38892 !important',
          backgroundColor: '#fff6f7 !important',
          borderRadius: '40px !important',
          boxShadow: "none !important"
        }}>Done</Button>
        {/* <Button size="small">Comment</Button> */}
      </CardActions>
    </Card>
  );
}

export default TaskCard;